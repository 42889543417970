import React, { useEffect, useState } from "react";
import classes from "./CategoryPage.module.css";
import Layout from "../Components/Layout";
import Navbar from "../Components/Navbar";
import { useSelector } from "react-redux";
import {
  FetchCartoonsApi,
  FetchGamesApi,
  FetchYogaVideosApi,
  GetNewsByCategoryApi,
  UserChatHistoryApi,
} from "../Api/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Carousel from "../Components/Carousel";
import SmallLoader from "../Components/Loaders/SmallLoader";
import CategoryDragSection from "../Components/Category-Section/CategoryDragSection";
import CategoryTitle from "../Components/Category-Section/CategoryTitle";
import { motion } from "framer-motion";
import CategoryMenu from "../Components/CategoryMenu";
import CategoryNewsMenu from "../Components/CategoryNewsMenu";
import moment from "moment-timezone";
import CategoryNews from "../Components/Category-Section/CategoryNews";

const CategoryPage = () => {
  const { expand } = useSelector((state) => state?.sidebarSlice);
  const { user } = useSelector((state) => state?.userSlice);
  const { data } = useSelector((state) => state?.dataSlice);
  const [chatHistory, setChatHistory] = useState([]);
  const [chatHistoryLoading, setChatHistoryLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryDataLoading, setCategoryDataLoading] = useState(true);
  const [category, setCategory] = useState("");

  const [newsActive, setNewsActive] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    setNewsActive({});
    fetchChatHistory();
    const urlParams = new URLSearchParams(window.location.search);
    const cid = urlParams.get("cid");
    const query = urlParams.get("query");

    // const allowedCategories = ["games", "exercise", "news", "cartoons"];
    const allowedCategories = ["games", "news","exercise"];
    const allowedQueries = ["sports", "entertainment", "technology"];

    if (allowedCategories.includes(cid)) {
      setCategory(cid);
      // if (cid === "cartoons") {
      //   fetchCartoons();
      // }
      if (cid === "exercise") {
        fetchExercise();
      }
      if (cid === "games") {
        fetchGames();
      }
      if (query && !allowedQueries.includes(query)) {
        navigate("/app");
      }
      if (cid === "news" && allowedQueries.includes(query)) {
        fetchNews(query);
        return;
      }
      if (cid === "news") {
        fetchNews("All");
      }
    } else {
      navigate("/app");
    }
  }, [window.location.search, data]);

  const fetchChatHistory = async () => {
    try {
      setChatHistoryLoading(true);
      const response = await UserChatHistoryApi(
        { msisdn: user?.msisdn },
        user?.token
      );
      setChatHistory(response?.data?.result);
      setChatHistoryLoading(false);
    } catch (error) {
      setChatHistoryLoading(false);
      toast.error(
        error?.response?.data?.err ||
          error?.data?.err ||
          error?.message ||
          error
      );
    }
  };

  // const fetchCartoons = async () => {
  //   try {
  //     setCategoryDataLoading(true);
  //     const response = await FetchCartoonsApi(user?.token);
  //     const resultArray = [];
  //     const uniqueSubCategoryIds = Array.from(
  //       new Set(response?.data?.categories.map((item) => item.sub_cat_id))
  //     );

  //     uniqueSubCategoryIds.forEach((subCategoryId) => {
  //       const filteredVideos = response?.data?.videos.filter(
  //         (video) => video.sub_category_id == subCategoryId
  //       );

  //       const categoryName = response?.data?.categories.find(
  //         (item) => item.sub_cat_id == subCategoryId
  //       )?.name;
  //       resultArray.push({
  //         videos: filteredVideos,
  //         category: categoryName,
  //       });
  //     });

  //     setCategoryData(resultArray);
  //     setCategoryDataLoading(false);
  //   } catch (error) {
  //     setCategoryDataLoading(false);
  //     toast.error(
  //       error?.response?.data?.message ||
  //         error?.data?.message ||
  //         error?.message ||
  //         error
  //     );
  //   }
  // };

  const fetchExercise = async () => {
    try {
      setCategoryDataLoading(true);
      const response = await FetchYogaVideosApi();
      setCategoryDataLoading(false);
      let resultArray = [];
      // resultArray.push(
      //   {
      //     videos: response?.data?.slice(0, 7),
      //     category: "Exercise",
      //   },
      //   {
      //     videos: response?.data?.slice(8, 16),
      //     category: "Stay Healthy",
      //   },
      //   {
      //     videos: response?.data?.slice(16, 24),
      //     category: "Fit & Fine",
      //   },
      //   {
      //     videos: response?.data?.slice(24, 32),
      //     category: "Fit",
      //   }
      // );

      resultArray.push(
        {
          videos: response?.data?.slice(0, 7),
          category: "Exercise",
        },
        {
          videos: response?.data?.slice(8, 16),
          category: "Stay Healthy",
        },
        {
          videos: response?.data?.slice(16, 24),
          category: "Fit & Fine",
        },
        {
          videos: response?.data?.slice(24, 32),
          category: "Fit",
        }
      );

      setCategoryData(resultArray);
    } catch (error) {
      setCategoryDataLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const fetchGames = async () => {
    try {
      setCategoryDataLoading(true);
      const response = await FetchGamesApi();
      const filteredGames = response?.data?.filter(
        (data) => data?.category === "Games"
      );
      setCategoryDataLoading(false);
      let resultArray = [];
      resultArray.push(
        {
          videos: filteredGames.slice(0, 7),
          category: "Top Games",
        },
        {
          videos: filteredGames.slice(7, 11),
          category: "Mind Refreshing",
        }
      );
      setCategoryData(resultArray);
    } catch (error) {
      setCategoryDataLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const fetchNews = async (query) => {
    try {
      setCategoryDataLoading(true);
      const response = await GetNewsByCategoryApi(
        {
          category: query,
          datetime: moment().format("YYYY-MM-DD"),
        },
        user?.token
      );

      let resultArray = [];
      resultArray.push(
        {
          news: response?.data?.result?.news.slice(0, 7),
          category: "",
        },
        {
          news: response?.data?.result?.news.slice(8, 16),
          category: "",
        },
        {
          news: response?.data?.result?.news.slice(17, 24),
          category: "",
        },
        {
          news: response?.data?.result?.news.slice(25, 32),
          category: "",
        },
        {
          news: response?.data?.result?.news.slice(33, 40),
          category: "",
        },{
          news: response?.data?.result?.news.slice(41, 48),
          category: "",
        }
      );

      setCategoryData(resultArray);

      setCategoryDataLoading(false);
    } catch (error) {
      setCategoryDataLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const [leftConstraint, setLeftConstraint] = useState(0);
  useEffect(() => {
    const updateConstraints = () => {
      if (window.innerWidth < 750) {
        setLeftConstraint(-300);
      } else {
        setLeftConstraint(0);
      }
    };

    updateConstraints();
    window.addEventListener("resize", updateConstraints);

    return () => {
      window.removeEventListener("resize", updateConstraints);
    };
  }, []);

  const onClickHandler = (id) => {
    navigate(`/app/category/play?cid=${category}&id=${id}`);
  };

  const onClickNewsHandler = (newsData, idx, navigateToNews) => {
    if (navigateToNews) {
      window.open(newsData?.url, "_blank");
      return;
    }
    if (newsActive?.title === newsData?.title && newsActive?.idx === idx) {
      setNewsActive({});
    } else {
      setNewsActive({ title: newsData?.title, idx: idx });
    }
  };

  return (
    <Layout
      expand={expand}
      chatHistoryLoading={chatHistoryLoading}
      chatHistory={chatHistory}
      chat_id={null}
      style={{ maxHeight: "unset" }}
    >
      <Navbar
        chatHistoryLoading={chatHistoryLoading}
        chatHistory={chatHistory}
        chat_id={null}
      />
      <div className={classes.category_main_container}>
        {category === "news" ? (
          <>
            <CategoryNewsMenu leftConstraint={leftConstraint} />
            {categoryDataLoading ? (
              <SmallLoader />
            ) : (
              <>
                {categoryData?.map((dataItem, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <CategoryTitle title={dataItem?.category} />

                      <CategoryNews
                        data={dataItem?.news}
                        onClickHandler={onClickNewsHandler}
                        slice={false}
                        newsActive={newsActive}
                      />
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </>
        ) : (
          <>
            <CategoryMenu leftConstraint={leftConstraint} />
            {categoryDataLoading ? (
              <SmallLoader />
            ) : (
              <>
                {categoryData?.map((dataItem, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <CategoryTitle title={dataItem?.category} />
                      <CategoryDragSection
                        slice={false}
                        data={dataItem?.videos}
                        dataItemKeys={
                          category === "cartoons"
                            ? { imgurl: "imgurl", name: "name" }
                            : category === "exercise"
                            ? { imgurl: "imageFiveUrl", name: "name" }
                            : { imgurl: "img_url", name: "game_name" }
                        }
                        cardType={category}
                        onClickHandler={onClickHandler}
                      />
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default CategoryPage;
